import axios from 'axios';

import { Logger } from './loggerType';
import { LogLevel, LogLevelDesc } from './LogLevel';

type ENV = 'LOC' | 'DEV' | 'STG' | 'PROD';

const LOG_SERVER_URL = 'https://log.zep.us:32681';
const DEBOUNCE_TIME = 1000;

class LogstashLogger implements Logger {
  private _logLevel: LogLevel;
  private _logEntries: object[];
  private _timer?: NodeJS.Timeout;
  private _env: ENV;

  constructor() {
    this._logLevel = new LogLevel();
    this._env = (process.env.NEXT_PUBLIC_STAGE ?? 'LOC') as ENV;
    this._logEntries = [];
  }

  // Ref: https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
  private _encodeBase64(str: string) {
    const encoder = new TextEncoder();
    const bytes = encoder.encode(str);
    const binString = Array.from(bytes, byte =>
      String.fromCodePoint(byte),
    ).join('');
    return btoa(binString);
  }

  private async _sendLogToServer(logEntries: object[]) {
    return axios.post(
      LOG_SERVER_URL,
      // 로그 서버와의 메시징 프로토콜: 1) base64 인코딩 2번 2) arraydata라는 key에 묶어서 보내기
      // Ref: https://zep-us.slack.com/archives/C05KT00K2AG/p1724899743779239?thread_ts=1724836752.963859&cid=C05KT00K2AG
      btoa(this._encodeBase64(JSON.stringify({ arraydata: logEntries }))),
      {
        headers: {
          'Content-Type': 'text/plain',
        },
      },
    );
  }

  private _log(
    level: LogLevelDesc,
    message: string,
    options?: {
      additionalData?: object;
      shouldDebounce?: boolean;
    },
  ) {
    const { additionalData = {}, shouldDebounce = true } = options ?? {};

    if (!this._logLevel.shouldLog(level)) {
      return;
    }

    if (this._env === 'LOC') {
      console.log(`[${level}] ${message}`, additionalData);
    }

    this._logEntries.push({
      level,
      env: this._env,
      message,
      additionalData,
      createdAt: new Date().toISOString(),
    });

    const sendLogToServer = () => {
      this._sendLogToServer(this._logEntries)
        .then(() => {
          this._logEntries = [];
        })
        .catch(() => {
          // Note: 메모리를 너무 많이 쓰지 않기 위해 에러 응답을 받는 경우 로그가 1000개(약 1MB) 이상 쌓이면 로그를 버립니다.
          if (this._logEntries.length >= 1000) {
            console.warn(
              'Server request failed and log entries exceeded 1000. Clearing log buffer.',
            );
            this._logEntries = [];
          }
        })
        .finally(() => {
          this._timer = undefined;
        });
    };

    if (shouldDebounce) {
      if (this._timer) {
        clearTimeout(this._timer);
      }

      // Note: 서버에 너무 많은 요청을 보내지 않도록 debounce
      this._timer = setTimeout(sendLogToServer, DEBOUNCE_TIME);
    } else {
      sendLogToServer();
    }

    // Note: 메모리를 너무 많이 쓰지 않기 위해 로그 엔트리가 5000개(약 5MB)를 초과하면 서버 응답과 무관하게 로그를 버립니다.
    if (this._logEntries.length >= 5000) {
      console.warn('Log entries exceeded 5000. Clearing log buffer.');
      this._logEntries = [];
    }
  }

  setLogLevel(level: LogLevelDesc) {
    this._logLevel.setLevel(level);
  }

  debug: Logger['debug'] = (message, options) => {
    this._log('DEBUG', message, options);
  };

  info: Logger['info'] = (message, options) => {
    this._log('INFO', message, options);
  };

  warn: Logger['warn'] = (message, options) => {
    this._log('WARN', message, options);
  };

  error: Logger['error'] = (message, options) => {
    this._log('ERROR', message, options);
  };
}

export const logstashLogger = new LogstashLogger();
