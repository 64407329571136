import { v4 as uuidv4 } from 'uuid';

import { Logger, logstashLogger } from '@zep/logger';
import { getDeviceInformation } from '@zep/utils';

class ZepEventTracker {
  private logger: Logger;
  private userId: string | undefined;
  private sessionId: string;
  metadata: Record<string, unknown>;

  constructor() {
    this.logger = logstashLogger;
    this.sessionId = this.getSessionId();
    this.initializeMetadata();
  }

  async initializeMetadata() {
    this.metadata = {};

    if (typeof window !== 'undefined') {
      const { isApp, browserName, osName, isMobile } = getDeviceInformation();
      this.metadata.isApp = isApp;
      this.metadata.browser = browserName;
      this.metadata.os = osName;
      this.metadata.isMobile = isMobile;
      this.metadata.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  }

  public setUserId(id: string | undefined) {
    this.userId = id;
  }

  public track(eventName: string, eventProperties?: Record<string, unknown>) {
    this.logger.info(eventName, {
      additionalData: {
        type: 'ZEP_CLIENT_EVENT',
        event: {
          name: eventName,
          properties: eventProperties,
          metadata: this.metadata,
          userId: this.userId,
          sessionId: this.sessionId,
        },
      },
    });
  }

  private getSessionId(): string {
    if (typeof window === 'undefined') {
      return uuidv4();
    }

    const storageKey = '@p@@ze__u_id';
    let userId = window.localStorage.getItem(storageKey);
    if (userId == null) {
      userId = uuidv4();
      window.localStorage.setItem(storageKey, userId);
    }
    return userId;
  }
}

export const zepEventTracker = new ZepEventTracker();
