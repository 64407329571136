import {
  Identify,
  identify,
  setUserId,
  track,
} from '@amplitude/analytics-browser';

import { getDeviceInformation, StatefulPQueue } from '@zep/utils';

import { event as gaEvent } from './gtag';
import { zepEventTracker } from './zepEventTracker';

const queue = new StatefulPQueue({ autoStart: false });

type UserProperties = Record<string, boolean | string | null>;

function setUserProperties(records: UserProperties) {
  const identifyEvent = new Identify();
  Object.entries(records).forEach(([key, value]) => {
    identifyEvent.set(key, String(value));
  });

  return identify(identifyEvent).promise;
}

const trackEvent = async (...params: Parameters<typeof track>) => {
  const [eventName, eventProperties, eventOptions] = params;
  gaEvent(String(eventName), eventProperties);
  return track(eventName, eventProperties, eventOptions).promise;
};

export const zepAnalytics: ZepAnalytics = {
  track: async (eventName, eventProperties) => {
    if (!queue.isReady) {
      return queue.add(() => {
        zepEventTracker.track(eventName, eventProperties);
        return trackEvent(eventName, eventProperties);
      });
    } else {
      zepEventTracker.track(eventName, eventProperties);
      return trackEvent(eventName, eventProperties);
    }
  },

  setAmplitudeUserId: (userId: string) => {
    setUserId(userId);
    setUserProperties({ IS_LOGIN: true });
    zepEventTracker.setUserId(userId);
  },

  resetAmplitudeUserId: () => {
    setUserId(undefined);
    setUserProperties({ IS_LOGIN: false });
    zepEventTracker.setUserId(undefined);
  },

  setDefaultUserProperties: () => {
    const { isApp, browserName, osName, isMobile } = getDeviceInformation();
    const records = {
      BROWSER: String(browserName),
      APP: String(isApp),
      OS: String(osName),
      MOBILE: String(isMobile),
    };
    setUserProperties(records).then(() => {
      queue.setReady(true).start();
    });
  },
};

type ZepAnalytics = {
  track: (
    eventName: string,
    eventProperties?: Record<string, unknown>,
  ) => ReturnType<typeof track>['promise'];
  setAmplitudeUserId: (userId: string) => void;
  resetAmplitudeUserId: () => void;
  setDefaultUserProperties: () => void;
};
